import React, { useEffect, useRef } from "react"

const Comment = ({ comment }) => {
  const ref = useRef()
  useEffect(() => {
    ref.current.outerHTML = `<!-- ${comment} -->`
  }, [comment])

  return <span ref={ref}></span>
}

export default Comment
