/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import Comment from "./comment"
import "./layout.css"

const Layout = ({ children, pageName, header = true, footer = true }) => {
  return (
    <div className={pageName}>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Kameron&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Comment comment="Website Template by freewebsitetemplates.com" />

      {header && <Header currentPageName={pageName} />}
      <main>{children}</main>

      {footer && <Footer />}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
