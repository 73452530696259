import React from "react"
import Twitter from "../images/twitter.png"

const SocialList = () => {
  return (
    <ul className="social">
      {/* <li>
          <a href="http://freewebsitetemplates.com/go/twitter/" id="twitter">
            twitter
          </a>
        </li> */}
      <li>
        <a
          href="https://untappd.com/RotationBeerCo"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://66.media.tumblr.com/avatar_c9d5f8a3bb78_128.pnj"
            alt="Untappd"
          />
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/rotationbeerco/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://facebookbrand.com/wp-content/uploads/2016/05/instagram-v051916.png?w=1024&h=1024"
            alt="Instagram"
          />
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/rotationbeerco"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Twitter} alt="Twitter" />
        </a>
      </li>
      <li>
        <a
          href="https://www.facebook.com/Rotation-Beer-Co-103076921114926/"
          id="facebook"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://en.facebookbrand.com/wp-content/uploads/2019/04/f_logo_RGB-Hex-Blue_512.png"
            alt="Facebook"
          />
        </a>
      </li>
    </ul>
  )
}

export default SocialList
