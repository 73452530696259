import React from "react"
import SocialList from "./socialList"

const Footer = () => (
  <footer id="footer">
    <div className="social">
      <p>
        &copy; 2019 by Alexandria Society of Homebrewers. <br />
        All rights reserved.
      </p>
      <SocialList />
    </div>
  </footer>
)

export default Footer
