import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/logo.png"

const Header = ({ siteTitle, currentPageName }) => {
  const maybeSelected = targetPageName =>
    currentPageName === targetPageName ? "selected" : ""

  const pageMap = {
    home: "/",
    about: "/about",
    beers: "/beers",
    contact: "/contact",
    blog: "/blog",
  }
  return (
    <header id="header">
      <div className="logo">
        <img src={Logo} alt="Rotation Beer" />
        {currentPageName === "home" && <h1>Rotation Beer</h1>}
      </div>
      <nav>
        <ul>
          {Object.keys(pageMap).map(page => (
            <li key={page} className={maybeSelected(page)}>
              <Link to={pageMap[page]}>{page}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
